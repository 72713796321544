import "./about.css";
import Award from "../../img/ait.png";

const About = () => {
  return (
    <div>
      {/* <Navbar /> */}

      <div className="a">
        <div className="a-left">
          <div className="a-card bg"></div>
          <div className="a-card">
            <img
              src="https://www.bgosoftware.com/blog/wp-content/uploads/2016/03/insidepost_coding.jpg"
              alt=""
              className="a-img"
            />
          </div>
        </div>
        <div className="a-right">
          <h1 className="a-title">About Me</h1>
          <p className="a-sub">
            I am a restless person looking for new challenges. Status in
            continuous learning.
          </p>
          <p className="a-desc">
            My name is Juan Baque. I was born in Spain in 1983 and moved to
            Australia at the beginning of 2016 looking for adventures, improving
            my English and learning how to surf. I have a background as a
            journalist back home and after improving my English i decided to
            jump into the developing world in 2019 so i enrolled AIT(Academy of
            Information Technology) to study the Bachelor's degree in Mobile App
            development. At the end of 2022 I will be graduated and ready to
            start a new chapter as a professional developer.
          </p>
          <div className="a-award">
            <img src={Award} alt="" className="a-award-img" />
            <div className="a-award-texts">
              <h4 className="a-award-title">
                Bachelor's degree in Mobile App development
              </h4>
              <p className="a-award-desc">
                One term away to finally jump to the industry.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div />
    </div>
  );
};

export default About;

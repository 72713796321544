import "./contact.css";
import Phone from "../../img/phone.png";
import Email from "../../img/email.png";
import Address from "../../img/address.png";
import { useContext, useRef, useState } from "react";
import emailjs from "emailjs-com";
import { ThemeContext } from "../../context";

const Contact = () => {
  const formRef = useRef();
  const [done, setDone] = useState(false)
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  const [user_email, setUser_email] = useState('');
    const [user_name, setUser_name] = useState('');
    const [user_subject, setUser_subject] = useState('');
    const [message, setMessage] = useState('');


  const handleSubmit = (e) => {
    if (user_name && user_email && user_subject && message) {
      
      

    e.preventDefault();
    emailjs
      .sendForm(
        "service_rofr1ok",
        "template_9q5p0n2",
        formRef.current,
        "mFWwnorBm0KLfztwz"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true)
          //this would be for uncontrolled inputs
          // e.target.reset();
            setUser_name('');
            setUser_email('');
            setUser_subject('');
            setMessage('');


        },
        (error) => {
          console.log(error.text);
        }
      ); }
     else {
      alert('Please insert your mail');
      e.preventDefault();

  }
      
  };

  return (
    <div className="c">
      <div className="c-bg"></div>
      <div className="c-wrapper">
        <div className="c-left">
          <h1 className="c-title">Let's discuss your project</h1>
          <div className="c-info">
            <div className="c-info-item">
              <img src={Phone} alt="" className="c-icon" />
              +61 434 226 089
            </div>
            <div className="c-info-item">
              <img className="c-icon" src={Email} alt="" />
              jjjbaque@gmail.com
            </div>
            <div className="c-info-item">
              <img className="c-icon" src={Address} alt="" />
              North Bondi NSW 2026 Australia
            </div>
          </div>
        </div>
        <div className="c-right">
          <p className="c-desc">
            <b>Have you got an idea?</b> Get in touch. Always available for
            freelancing if the right project comes along.
          </p>
          <form ref={formRef} onSubmit={handleSubmit}>
            <input style={{backgroundColor: darkMode && "#333"}} type="text" placeholder="Name" value={user_name} onChange={e => setUser_email(e.target.value)} />
            <input style={{backgroundColor: darkMode && "#333"}} type="text" placeholder="Subject" value={user_subject} onChange={e => setUser_subject(e.target.value)} />
            <input style={{backgroundColor: darkMode && "#333"}} type="text" placeholder="Email" value={user_email} onChange={e => setUser_email(e.target.value)} />
            <textarea style={{backgroundColor: darkMode && "#333"}} rows="5" placeholder="Message" value={message} onChange={e => setMessage(e.target.value)} />
            <button>Submit</button>
            {done && "  Thank you, I will contact you shortly. "}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;


export const products = [
  {
    id: 1,
    img:"../../img/introductiontoweb.png",
    link: "https://github.com/juanihno/introductionToWeb",
  },
  {
    id: 2,
    img: "../../img/digitalprojectmanagement.png",
    link: "https://github.com/juanihno/Schultzenburg-Murder-Haunt.git",
  },
  {
    id: 3,
    img: "../../img/ux_ui.png",
  },
  {
    id: 4,
    img:"../../img/javasd.png",
    link: "https://github.com/juanihno/java.git",
  },
  {
    id: 5,
    img:"../../img/advancedweb.png",
    link:"https://github.com/spikeganush/ait-course-review2.git",
  },
  {
    id: 6,
    img:"../../img/enterprisesystems.png",
    link: "https://github.com/juanihno/enterprise-systems.git",
  },
  {
    id: 7,
    img:"../../img/androidapp.png",
    link: "https://github.com/juanihno/myTRecipeApp-android.git",
  },
  {
    id: 8,
    img:"../../img/crossplatform.png",
    link: "https://github.com/juanihno/calendarapp.git",
  },{
    id: 9,
    img:"../../img/ios.png",
    link:"https://github.com/juanihno/MobileAppIOS-MySurfBoard.git",
  },
];
